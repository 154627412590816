import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { NetworkBar } from "@bkry/bowline-components/NetworkBar";
import { TrunkatedAddress } from "@bkry/bowline-components/Utils";
import {
  genericAbiRead,
  getContractAttributes,
} from "@bkry/bowline-redux/contracts";
import { getMintTierAttributes } from "@bkry/bowline-redux/mintTiers";
import {
  getTokenAttributes,
  getTokenIdByIdentifier,
  showToken,
} from "@bkry/bowline-redux/tokens";
import { env } from "@bkry/bowline-utils";
import { useWeb3React } from "@web3-react/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import openseaIcon from "../assets/icons/opensea.svg";
import raribleIcon from "../assets/icons/rarible.svg";
import MintButtonSlim from "./utils/MintButtonSlim";

const { REACT_APP_PROJECT_ID } = env;

const MintTierHero = ({ mintTierId, whitelistId, address }, context) => {
  const dispatch = useDispatch();
  const { account, provider } = useWeb3React();
  const [mintPrice, setMintPrice] = useState(null);
  const [mintEnabled, setMintEnabled] = useState(false);
  const [availableAmount, setAvailableAmount] = useState(0);
  const [alreadyMintedAmount, setAlreadyMintedAmount] = useState(0);

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const dictionaryTokenId = useSelector((state) =>
    getTokenIdByIdentifier(state, {
      contractAddress: mintTier?.contract_address,
      identifier: mintTier?.token_identifier,
    })
  );

  const tokenData = useSelector((state) =>
    getTokenAttributes(state, dictionaryTokenId)
  );

  const contractData = useSelector((state) =>
    getContractAttributes(state, mintTier?.contract_address)
  );

  const getMintEnabled = async () => {
    if (!mintTier?.enabled_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.enabled_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          account: account,
          key: account,
          owner: account,
          id: mintTier?.token_identifier,
          tokenIdentifier: mintTier?.token_identifier,
        },
        successCallbackFnc: (data) => {
          setMintEnabled(data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Mint Price");
          // alert(data);
          // dispatch(mintError(data));
          console.log("mint enabled error", data);
        },
      })
    );
  };

  const getAvailableAmount = async () => {
    if (!mintTier?.available_amount_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.available_amount_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          account: account,
          key: account,
          owner: account,
          id: mintTier?.token_identifier,
          tokenIdentifier: mintTier?.token_identifier,
        },
        successCallbackFnc: (data) => {
          console.log("available amount", data);
          setAvailableAmount(
            parseInt(data?._isBigNumber ? data?.toString() : data)
          );
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Available Mint Amount");
          // alert(data);
          console.log("available amount", data);
          // dispatch(mintError(data));
        },
      })
    );
  };

  const getAlreadyMintedAmount = async () => {
    if (!mintTier?.already_minted_amount_abi?.name) return true;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.already_minted_amount_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          account: account,
          key: account,
          owner: account,
          id: mintTier?.token_identifier,
          tokenIdentifier: mintTier?.token_identifier,
        },
        successCallbackFnc: (data) => {
          setAlreadyMintedAmount(
            parseInt(data?._isBigNumber ? data.toString() : data)
          );
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error on Already Minted Amount");
          // alert(data);
          // dispatch(mintError(data));
          console.log("already minted", data);
        },
      })
    );
  };

  const getMintPrice = async () => {
    if (!mintTier?.price_abi?.name) return 0;
    dispatch(
      genericAbiRead({
        functionAbi: mintTier?.price_abi,
        contractAddress: mintTier?.contract_address,
        account: account,
        provider: provider,
        genericInputs: {
          account: account,
          key: account,
          owner: account,
          id: mintTier?.token_identifier,
          tokenIdentifier: mintTier?.token_identifier,
        },
        successCallbackFnc: (data) => {
          setMintPrice(data?._isBigNumber ? data.toString() : data);
          // dispatch(mintingInProgressSuccess());
        },
        errorCallbackFnc: (data) => {
          // alert("Error");
          // alert(data);
          // dispatch(mintError(data));
          console.log("mintPrice error", data);
        },
      })
    );
  };

  let tokenDetailData = tokenData?.metadata
    ? JSON.parse(tokenData.metadata)
    : {};

  /** TODO replacement should not be done here / image should be imported into the API and return a thumbnail */
  const imageUrl = tokenData?.image || tokenDetailData?.image;
  const animationUrl = tokenData?.token_animation_original?.url;

  const tokenName = tokenData?.name || "";

  useEffect(() => {
    getMintPrice();
    getAvailableAmount();
  }, [account]);

  useEffect(() => {
    getMintEnabled();
    getAlreadyMintedAmount();
    const interval = setInterval(() => {
      getMintEnabled();
      getAlreadyMintedAmount();
    }, 5000); //refresh every 5 seconds enabled and available amount
    return () => clearInterval(interval);
  }, []);

  const dispatchApi = async () => {
    if (
      mintTier?.token_identifier &&
      tokenData?.identifier != mintTier?.token_identifier &&
      tokenData?.contract_address != mintTier?.contract_address
    ) {
      dispatch(
        showToken({
          projectId: REACT_APP_PROJECT_ID,
          identifier: mintTier?.token_identifier,
          contractAddress: mintTier?.contract_address,
        })
      );
    } else {
      // TODO maybe move those checks to another place? also we should maybe check for renewing after X minutes or so
      console.log(
        "[DEBUG] Did not fire API requests since data is already loaded",
        tokenData?.contract_address,
        tokenData?.identifier
      );
    }
  };

  useEffect(dispatchApi, [dispatch, mintTier]);

  return (
    <>
      <Row>
        <Col xs={{ size: 12, order: 2 }} lg={{ size: 7, order: 1 }}>
          <h1 className="mb-4">{mintTier?.title}</h1>
        </Col>
        <Col xs={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }}>
          <div className="d-flex align-items-center mb-4">
            <span className="border rounded-pill p-2 small me-3">
              <TrunkatedAddress address={mintTier?.contract_address} />
            </span>
            {contractData && (
              <div className="ms-auto">
                <a
                  href={`https://${
                    contractData.network === "goerli" ? "testnet." : ""
                  }rarible.com/collection/${
                    contractData.network === "polygon" ? "polygon/" : ""
                  }${contractData.address}/items`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={raribleIcon} height={30} alt="rarible-icon" />
                </a>
                <a
                  href={`https://${
                    contractData.network === "ethereum" ||
                    contractData.network === "polygon"
                      ? ""
                      : "testnets."
                  }opensea.io/assets/${contractData.network}/${
                    contractData.address
                  }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={openseaIcon} height={30} alt="opensea-icon" />
                </a>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={{ size: 12, order: 2 }} lg={{ size: 7, order: 1 }}>
          <div className="me-5 mb-5">
            {mintTier?.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: mintTier?.description.replace(
                    /href=/g,
                    "target='_blank' rel='noopener noreferrer' href="
                  ),
                }}
              />
            )}
          </div>
          {account ? (
            <>
              <NetworkBar contractByAddress={mintTier?.contract_address} />
              <MintButtonSlim
                whitelistId={whitelistId}
                mintTierId={mintTierId}
                mintPrice={mintPrice}
                // usdMintPrice={usdMintPrice}
                availableAmount={availableAmount}
                alreadyMintedAmount={alreadyMintedAmount}
                contractAddress={mintTier?.contract_address}
                disabled={
                  !mintEnabled ||
                  availableAmount <= 0 ||
                  availableAmount - alreadyMintedAmount <= 0
                }
                address={address}
              />
            </>
          ) : (
            <div className="text-center">
              <p>{context.t("To mint this NFT please connect your wallet")}</p>
              <ConnectWallet
                buttonColor="primary"
                buttonSize="xl"
                buttonId="nav-connect-wallet"
              />
            </div>
          )}
        </Col>
        <Col xs={{ size: 12, order: 1 }} lg={{ size: 5, order: 2 }}>
          {animationUrl ? (
            <div>
              <ReactPlayer
                width="100%"
                height="auto"
                url={animationUrl}
                controls={true}
                loop={true}
                muted={true}
                playing={true}
                playsinline
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload noplaybackrate",
                      disablePictureInPicture: true,
                    },
                  },
                }}
                className="mb-5"
              />
            </div>
          ) : (
            <>
              {imageUrl && (
                <div className="img-div position-relative text-center">
                  <img
                    src={imageUrl}
                    style={{ maxHeight: "80vH" }}
                    alt={tokenName}
                    className="img-fluid mb-5"
                  />
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MintTierHero.contextTypes = {
  t: PropTypes.func,
};

MintTierHero.propTypes = {
  whitelistId: PropTypes.number,
  mintTierId: PropTypes.string,
  address: PropTypes.string,
};

MintTierHero.defaultProps = {};

export default MintTierHero;
