import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Input,
} from "reactstrap";
import PropTypes from "prop-types";
// import WertModule from "@wert-io/module-react-component";
import WertWidget from "@wert-io/widget-initializer";
import { v4 as uuidv4 } from "uuid";
import { ethers } from "ethers";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  getMintTierAttributes,
  generateWertSignature,
  validateMintTier,
  mintMintTier,
} from "@bkry/bowline-redux/mintTiers";
import {
  mintingInProgressSuccess,
  genericAbiWrite,
  mintError,
  transactionSuccess,
} from "@bkry/bowline-redux/contracts";
import { isServer } from "@bkry/bowline-redux";
import { addToDictionary } from "@bkry/bowline-redux/transactionRequests";
import { checkCurrency } from "@bkry/bowline-utils";
import { env } from "@bkry/bowline-utils";
const {
  REACT_APP_WERT_PARTNER_ID,
  REACT_APP_WERT_ORIGIN,
  REACT_APP_PROJECT_ID,
} = env;
const MintButtonSlim = (
  { mintTierId, mintPrice, disabled, availableAmount, alreadyMintedAmount },
  context
) => {
  const dispatch = useDispatch();
  const { account, provider } = useWeb3React();
  const formattedMintPrice = mintPrice
    ? ethers.utils.formatEther(mintPrice)
    : mintPrice;
  const [balance, setBalance] = useState(null);
  const [showBuyModalButtons, setShowBuyModalButtons] = useState(true);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showInitialLoading, setShowInitialLoading] = useState(false);
  const [wertPaymentStatus, setWertPaymentStatus] = useState(null);
  const [wertError, setWertError] = useState(null);

  const [preventCloseAlert, setPreventCloseAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [voucherCode, setVoucherCode] = useState(null);

  useEffect(() => {
    if (!!account && !!provider) {
      let stale = false;

      provider
        .getBalance(account)
        .then((balance) => {
          if (!stale) {
            setBalance(balance.toString());
          }
        })
        .catch(() => {
          if (!stale) {
            setBalance(null);
          }
        });

      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, provider]);

  const hasEnoughBalance = mintPrice
    ? account &&
      balance &&
      ethers.utils.formatEther(balance) > ethers.utils.formatEther(mintPrice)
    : true;
  const maxAmount = availableAmount - alreadyMintedAmount;

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const [tokenCounter, setTokenCounter] = useState(1);
  const decrement = () => {
    if (tokenCounter > 1) {
      setTokenCounter(tokenCounter - 1);
    }
  };
  const increment = () => {
    if (!availableAmount || tokenCounter < maxAmount) {
      setTokenCounter(tokenCounter + 1);
    }
  };

  const validateMint = async () => {
    if (
      mintTier?.mint_tier_type === "unique_mint_vouchers" ||
      mintTier?.mint_tier_type === "secret_word"
    ) {
      console.log(`Mint Voucher: ${voucherCode}`);
      if (!voucherCode || voucherCode === "") {
        alert("Voucher is required");
        return;
      }
      dispatch(
        validateMintTier({
          mint_tier_id: mintTierId,
          project_id: REACT_APP_PROJECT_ID,
          contract_address: mintTier?.contract_address,
          voucher: {
            code: voucherCode,
          },
          callbackFnc: async (signedData) => {
            await handleMint(
              signedData?.data?.attributes?.mint_voucher_signature,
              (
                signedData?.data?.attributes?.signature_valid_until || ""
              ).toString()
            );
          },
        })
      );
    } else {
      await handleMint(); // ensure that we do no validate a standard or allowlist mint
    }
  };

  const handleMint = async (
    mintSignature = null,
    mintSignatureValidUntil = null
  ) => {
    setShowBuyModal(false);
    setShowBuyModalButtons(true);
    if (mintTier?.mint_tier_delivery_type === "bowline_relay") {
      const genericInputs = {
        quantity: tokenCounter,
        amount: tokenCounter,
        tokenReceiver: account,
        minter: account,
        wallet: account,
        account,
        mintpass: {
          // TODO  mintpass object should be build with signature_type
          wallet: account,
          tier: mintTier?.internal_tier_id,
          code: voucherCode,
        },
        mintvoucher: {
          // TODO  mintpass object should be build with signature_type
          wallet: account,
          tier: mintTier?.internal_tier_id,
          code: voucherCode,
          valid_until: mintSignatureValidUntil,
          token_identifier: mintTier?.token_identifier,
        },
        id: mintTier?.token_identifier,
        tokenIdentifier: mintTier?.token_identifier,
        mintpassSignature: "0x" + mintTier?.mint_pass_signature,
        mintvoucherSignature: "0x" + mintSignature,
        data: [],
      };

      let genericParameters = [];
      if (mintTier?.mint_abi?.inputs.length > 0) {
        genericParameters = mintTier?.mint_abi.inputs.map((input) => {
          return genericInputs[input.name];
        });
      }

      dispatch(
        mintMintTier({
          mint_tier_id: mintTierId,
          provider: provider,
          project_id: REACT_APP_PROJECT_ID,
          contract_address: mintTier?.contract_address,
          function_params: encodeURIComponent(
            JSON.stringify(genericParameters)
          ),
          additionalBody: { code: voucherCode },
          callbackFnc: (signedData) => {
            console.log("Successfully minted", signedData);
          },
          transactionCallbackFnc: (data) => {
            console.log("transfer data", data);
            dispatch(transactionSuccess(data));
          },
        })
      );
    } else {
      dispatch(
        genericAbiWrite({
          functionAbi: mintTier?.mint_abi,
          contractAddress: mintTier?.contract_address,
          account: account,
          payableValue: (tokenCounter * mintPrice).toString(),
          provider: provider,
          genericInputs: {
            account,
            quantity: tokenCounter,
            amount: tokenCounter,
            tokenReceiver: account,
            minter: account,
            mintpass: {
              // TODO  mintpass object should be build with signature_type
              wallet: account,
              tier: mintTier?.internal_tier_id,
            },
            id: mintTier?.token_identifier,
            tokenIdentifier: mintTier?.token_identifier,
            mintpassSignature: "0x" + mintTier?.mint_pass_signature,
            data: [],
          },
          successCallbackFnc: (data) => {
            console.log("success Data", data);
            dispatch(mintingInProgressSuccess(data));
          },
          errorCallbackFnc: (data) => {
            console.log("error Data", data);
            dispatch(mintError(data));
          },
          transactionCallbackFnc: (data) => {
            console.log("transfer data", data);
            dispatch(transactionSuccess(data));
          },
        })
      );
    }
  };

  const wertOptions = {
    partner_id: REACT_APP_WERT_PARTNER_ID,
    origin: REACT_APP_WERT_ORIGIN,
    container_id: "wert",
    click_id: uuidv4(),
    width: 400,
    height: 600,
    redirect_url: window.location.href,
    listeners: {
      loaded: () => setShowInitialLoading(false),
      close: () => console.log("WERT close"),
      position: (data) => console.log("WERT step:", data.step),
      error: (data) => setWertError(data),
      "payment-status": (data) => {
        setWertPaymentStatus(data);
        if (data?.status === "success") {
          dispatch(
            addToDictionary({
              id: data.tx_id,
              transactionPreview: context.t("Paid Transaction"),
              transactionRequestStatus: "success",
            })
          );
          setSuccessAlert(false);
          //"TODO: store transaction in bowline wallet"
        } else if (data?.status === "pending") {
          setSuccessAlert(true);
          //"TODO: store transaction in bowline wallet"
          dispatch(
            addToDictionary({
              id: data.tx_id,
              transactionPreview: context.t("Paid Transaction"),
              transactionRequestStatus: "pending",
            })
          );
        }
      },
    },
  };
  console.log(wertPaymentStatus, wertError);
  const preventCloseModal =
    wertPaymentStatus &&
    (wertPaymentStatus.status === "progress" ||
      typeof wertPaymentStatus.status === "undefined");

  const closeBuyModal = () => {
    if (!preventCloseModal) {
      setShowBuyModal(false);
      setShowBuyModalButtons(true);
      setWertPaymentStatus(null);
      setWertError(null);
      setPreventCloseAlert(false);
      setSuccessAlert(false);
      setShowInitialLoading(false);
    } else {
      setPreventCloseAlert(true);
    }
  };
  const truncateEth = (str, maxDecimalDigits) => {
    if (str.includes(".")) {
      const parts = str.split(".");
      return parts[0] + "." + parts[1].slice(0, maxDecimalDigits);
    }
  };
  const handleBuyMint = () => {
    setShowInitialLoading(true);
    setShowBuyModalButtons(false);

    const commodityAmountWei = (tokenCounter * mintPrice).toString();
    const commodityAmount = ethers.utils.formatEther(commodityAmountWei);

    const genericInputs = {
      quantity: tokenCounter,
      amount: tokenCounter,
      tokenReceiver: account,
      minter: account,
      account,
      mintpass: {
        // TODO  mintpass object should be build with signature_type
        wallet: account,
        tier: mintTier?.internal_tier_id,
        code: voucherCode,
      },
      id: mintTier?.token_identifier,
      tokenIdentifier: mintTier?.token_identifier,
      mintpassSignature: "0x" + mintTier?.mint_pass_signature,
      data: [],
    };

    let genericParameters = [];
    if (mintTier?.mint_abi?.inputs.length > 0) {
      genericParameters = mintTier?.mint_abi.inputs.map((input) => {
        return genericInputs[input.name];
      });
    }

    dispatch(
      generateWertSignature({
        mint_tier_id: mintTierId,
        project_id: REACT_APP_PROJECT_ID,
        commodity_amount: truncateEth(commodityAmount, 8),
        contract_address: mintTier.contract_address,
        function_params: encodeURIComponent(JSON.stringify(genericParameters)),
        callbackFnc: (signedData) => {
          const wertWidget = new WertWidget({
            ...signedData,
            ...wertOptions,
          });
          wertWidget.mount();
        },
      })
    );
  };

  const checkStatus =
    !isServer &&
    window.ethereum &&
    window.ethereum.networkVersion &&
    typeof window.ethereum.networkVersion === "string";

  // const usdPrice = parseFloat(usdMintPrice / 100000000);

  return (
    <>
      {(mintTier?.mint_tier_type === "unique_mint_vouchers" ||
        mintTier?.mint_tier_type === "secret_word") && (
        <div className="my-3 d-grid gap-2 col-sm-12 col-md-8 mx-auto">
          <Input
            placeholder={context.t("e.g. Voucher Code")}
            defaultValue={voucherCode}
            onChange={(evt) => {
              setVoucherCode(evt.target.value);
            }}
          />
        </div>
      )}

      <div className="my-3 d-grid gap-2 col-sm-12 col-md-10 mx-auto">
        {mintTier?.bowline_relay_transaction_id && (
          <pre>{mintTier?.bowline_relay_transaction_id}</pre>
        )}
        <div className="d-flex justify-content-around px-5 py-3">
          <Button
            disabled={
              (!!availableAmount && disabled) ||
              ((mintTier?.mint_tier_type === "unique_mint_vouchers" ||
                mintTier?.mint_tier_type === "secret_word") &&
                (!voucherCode || voucherCode === ""))
            }
            color="primary"
            onClick={
              mintPrice
                ? () => {
                    setShowBuyModal(true);
                    setShowBuyModalButtons(true);
                  }
                : validateMint
            }
          >
            {context.t("Mint")} {tokenCounter} Token{tokenCounter > 1 && <>s</>}{" "}
            for
            {mintPrice > 0 && (
              <>
                {" "}
                {truncateEth(
                  ethers.utils
                    .formatEther((tokenCounter * mintPrice).toString())
                    .toString(),
                  6
                )}
                {" ETH"}
              </>
            )}
          </Button>

          {availableAmount > 1 && (
            <>
              <Button
                className="ms-2"
                onClick={decrement}
                outline
                color="secondary"
                size="sm"
                disabled={tokenCounter <= 1}
              >
                <AiOutlineMinus size={20} />
              </Button>
              <div className="d-flex align-items-center mx-2">
                <span className="h1 fw-bold">{tokenCounter}</span>
              </div>
              <Button
                color="secondary"
                onClick={increment}
                size="sm"
                outline
                disabled={tokenCounter >= maxAmount}
              >
                <AiOutlinePlus size={20} />
              </Button>
            </>
          )}
        </div>
        <p className="text-center text-muted small">
          {alreadyMintedAmount} minted
        </p>
        <Modal isOpen={showBuyModal} toggle={closeBuyModal} scrollable centered>
          <ModalHeader
            toggle={closeBuyModal}
            className="text-dark bg-light fw-bold border-0 p-4"
          >
            {context.t("Buy Mint")}
          </ModalHeader>
          <ModalBody className="text-dark bg-white border-0 p-4">
            {/* div with centered content */}
            <div className="d-flex justify-content-center">
              {showInitialLoading && <Spinner color="dark" />}
            </div>
            {showBuyModalButtons && (
              <div>
                <p className="text-dark">
                  {context.t("Select an option to pay for this mint")}
                </p>
                <Button
                  className="mt-2"
                  block
                  disabled={!!availableAmount && disabled}
                  color="primary"
                  onClick={handleMint}
                >
                  {context.t("Pay with Crypto")}
                </Button>
                {!hasEnoughBalance && (
                  <span className="form-text text-dark">
                    {context.t(
                      "We realized that your balance is too low. You need to have at least "
                    )}
                    {formattedMintPrice}
                    {checkStatus && checkCurrency()}
                  </span>
                )}
                <Button
                  className="mt-4"
                  outline
                  block
                  disabled={!!availableAmount && disabled}
                  color="primary"
                  onClick={handleBuyMint}
                >
                  {context.t("Pay with Credit Card")}
                </Button>
              </div>
            )}
            {preventCloseModal && preventCloseAlert && (
              <Alert color="danger">
                {context.t("Please wait for the payment to be completed")}
              </Alert>
            )}
            {successAlert && (
              <Alert color="success">
                {context.t(
                  "The transaction has been sent and you will receive a notification in your bowline wallet"
                )}
              </Alert>
            )}
            <div className="d-flex justify-content-center" id="wert">
              {/* <WertModule options={wertOptions} /> */}
            </div>
          </ModalBody>
        </Modal>

        {!!availableAmount && disabled && (
          <>
            {availableAmount <= 0 ? (
              <p className="text-dark">
                {context.t("No tokens available for minting")}
              </p>
            ) : (
              <>
                {availableAmount - alreadyMintedAmount <= 0 ? (
                  <p className="text-dark">{context.t("Max tokens minted")}</p>
                ) : (
                  <p className="text-dark">
                    {context.t("Mint will start soon. Stay tuned!")}
                  </p>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MintButtonSlim.contextTypes = {
  t: PropTypes.func,
};

MintButtonSlim.propTypes = {
  // disabled: PropTypes.boolean,
  mintTierId: PropTypes.string,
  availableAmount: PropTypes.number,
  alreadyMintedAmount: PropTypes.number,
  mintPrice: PropTypes.string,
  disabled: PropTypes.bool,
};

MintButtonSlim.defaultProps = {};

export default MintButtonSlim;
