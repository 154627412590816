/**
 * The MintUpcoming container
 */
import { ErrorModal } from "@bkry/bowline-components/ErrorModal";
import { Page } from "@bkry/bowline-components/Page";
import { EditorLoader } from "@bkry/bowline-components/PageBuilder";
import {
  ErrorCodeResolver,
  GenericErrorModal,
  GenericSuccessModal,
  SuccessCodeResolver,
  GenericProcessingModal,
} from "@bkry/bowline-components/StatusModals";
import {
  BlockExplorerLink,
  ScrollToTop as useScrollToTop,
} from "@bkry/bowline-components/Utils";
import {
  getContractAttributes,
  getContractByAddress,
  mintErrorReset,
  resetTransactionSuccess,
  mintProgressReset,
} from "@bkry/bowline-redux/contracts";
import {
  getMintTierAttributes,
  listTiersForProject,
  mintMintTierErrorReset,
  validateMintTierErrorReset,
} from "@bkry/bowline-redux/mintTiers";
import {
  getTokenAttributes,
  getTokenIdByIdentifier,
} from "@bkry/bowline-redux/tokens";
import { env } from "@bkry/bowline-utils";
import { useWeb3React } from "@web3-react/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useParams, withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import MintTierHero from "../../components/MintTierHero";

const { REACT_APP_PROJECT_ID } = env;
/**
 * MintingTierDetail container used as app default landing page
 */
const MintingTierDetail = (props, context) => {
  useScrollToTop();
  const { mintTierId } = useParams();
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const mintSuccess = useSelector((state) => state?.contracts?.mintSuccess);

  const mintError = useSelector((state) => state?.contracts?.mintError);

  const mintTier = useSelector((state) =>
    getMintTierAttributes(state, mintTierId)
  );

  const dictionaryTokenId = useSelector((state) =>
    getTokenIdByIdentifier(state, {
      contractAddress: mintTier?.contract_address,
      identifier: mintTier?.token_identifier,
    })
  );

  const tokenData = useSelector((state) =>
    getTokenAttributes(state, dictionaryTokenId)
  );

  let tokenDetailData = tokenData?.metadata
    ? JSON.parse(tokenData.metadata)
    : {};

  const imageUrl = tokenData?.image || tokenDetailData?.image;
  const animationUrl = tokenData?.token_animation_original?.url;
  const tokenName = tokenData?.name || "";

  const lastTx = useSelector((state) => state?.contracts?.lastTx);
  const lastTxContractByAddress = useSelector((state) =>
    getContractByAddress(state, lastTx?.data?.relationships?.contract?.data?.id)
  );
  const lastTxContractData = useSelector((state) =>
    getContractAttributes(state, lastTxContractByAddress)
  );

  const lastTxNetwork = lastTxContractData?.network;

  const mintErrorMessage = useSelector(
    (state) => state?.contracts?.mintErrorMessage
  );
  const mintMintTierError = useSelector((state) => state?.mintTiers?.mintError);
  // const mintMintTierSuccess = useSelector((state) => state?.mintTiers?.minted);
  const mintingInProgress = useSelector(
    (state) => state?.contracts?.mintingInProgress
  );
  const awaitingTransactionConfirmation = useSelector(
    (state) => state?.contracts?.awaitingTransactionConfirmation
  );

  const mintMintingInProgress = useSelector(
    (state) => state?.mintTiers?.minting
  );
  const mintMinted = useSelector((state) => state?.mintTiers?.minted);

  const validatingInProgress = useSelector(
    (state) => state?.mintTiers?.validating
  );
  const codeValidated = useSelector((state) => state?.mintTiers?.validated);

  const validateMintTierError = useSelector(
    (state) => state?.mintTiers?.validateError
  );

  const mintPending = useSelector((state) => state?.contracts?.mintPending);

  useEffect(() => {
    dispatch(
      listTiersForProject({
        projectId: REACT_APP_PROJECT_ID,
        address: account,
      })
    );
    window.scrollTo(0, 0);
  }, [dispatch, REACT_APP_PROJECT_ID, account]);

  const mintTierIds = useSelector((state) => state?.mintTiers?.idsList);

  return (
    <Page id="MintTierDetail" title={context.t("Minting")}>
      {mintTierIds.length > 0 && (
        <div className="bg-light text-dark py-5">
          <Container>
            <MintTierHero mintTierId={mintTierId} key={mintTierId} />
          </Container>
        </div>
      )}
      <EditorLoader pageSlug={`minting/${mintTierId}`} />
      <GenericSuccessModal
        success={mintSuccess || false}
        // redirectUrl={`/minting`} TODO needed here?
        successReset={resetTransactionSuccess}
        title={tokenName || false}
        primaryAction={{
          title: context.t("Okay"),
          action: resetTransactionSuccess,
        }}
      >
        <SuccessCodeResolver>
          <p>
            {context.t("You successfully minted the {tokenTitle} Token.", {
              tokenTitle: tokenName,
            })}
          </p>
          <Row className="my-5">
            <Col xs="6">
              {animationUrl ? (
                <div>
                  <ReactPlayer
                    width="100%"
                    height="auto"
                    url={animationUrl}
                    controls={true}
                    loop={true}
                    muted={true}
                    playing={true}
                    playsinline
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload noplaybackrate",
                          disablePictureInPicture: true,
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <>
                  {imageUrl && (
                    <div className="img-div position-relative text-center">
                      <img
                        src={imageUrl}
                        style={{ maxHeight: "80vH" }}
                        alt={tokenName}
                        className="img-fluid mb-5"
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
            <Col xs="6">
              <p className="h4">{tokenName}</p>
              <p>{context.t("You can see it in your Wallet")}</p>
            </Col>
          </Row>
          {lastTx?.data?.attributes?.bowline_relay_transaction_id && (
            <div className="text-center">
              <BlockExplorerLink
                transactionHash={
                  lastTx?.data?.attributes?.bowline_relay_transaction_id
                }
                network={lastTxNetwork}
                className="text-muted small"
              >
                <FaInfoCircle className="me-2" />
                {lastTxNetwork === "polygon"
                  ? context.t("Check your transaction on Polygonscan")
                  : context.t("Check your transaction on Etherscan")}
              </BlockExplorerLink>
            </div>
          )}
        </SuccessCodeResolver>
      </GenericSuccessModal>

      <GenericProcessingModal
        processing={
          (mintMintingInProgress ||
            validatingInProgress ||
            mintingInProgress ||
            mintPending ||
            awaitingTransactionConfirmation) &&
          !mintMintTierError &&
          !validateMintTierError
        }
        primaryAction={{
          title: context.t("Close Modal"),
          action: mintProgressReset,
        }}
        processReset={mintProgressReset}
        title={context.t("Transaction in Progress")}
        steps={[
          {
            title: context.t("Validating Voucher Code"),
            content: <>{context.t("We are validating your voucher code.")}</>,
            status: mintingInProgress
              ? "done"
              : validatingInProgress
              ? "processing"
              : codeValidated
              ? "done"
              : "pending",
          },
          {
            title: context.t("Generating Airdrop"),
            content: (
              <>
                {context.t(
                  "The token is going to be airdropped to your wallet."
                )}
              </>
            ),
            status: mintingInProgress
              ? "done"
              : mintMintingInProgress
              ? "processing"
              : mintMinted
              ? "done"
              : "pending",
          },
          {
            title: context.t("Transaction is being processed"),
            content: (
              <>
                {context.t(
                  "The Transaction was sent to the blockchain and we are waiting for the result. Please be patient, this can take some time."
                )}
              </>
            ),
            status: mintingInProgress ? "processing" : "pending",
          },
        ]}
      >
        <p className="small">
          {context.t(
            "You can close this modal at any time - it does not affect your transaction. We will close the modal for you once we see that the transaction is mined."
          )}
        </p>
      </GenericProcessingModal>

      {mintErrorMessage?.errorMessage !== "transaction is not defined" && (
        <ErrorModal
          error={mintError}
          errorReset={mintErrorReset}
          customErrorBody={mintErrorMessage?.errorMessage}
        />
      )}
      {mintMintTierError && (
        <GenericErrorModal
          error={context.t("Something went wrong minting your token")}
          title={context.t("We could not mint your Token!")}
          errorReset={mintMintTierErrorReset}
          rawError={mintMintTierError?.attributes?.full_error_message}
          primaryAction={{
            title: context.t("Close"),
            action: mintMintTierErrorReset,
          }}
        >
          <ErrorCodeResolver codes={mintMintTierError?.attributes?.errors}>
            <p>
              {context.t(
                "Unfortunately something went wrong sending your transaction. Please try again. If the error remains please contact us at support@bowline.app and include the full error message."
              )}
            </p>
          </ErrorCodeResolver>
        </GenericErrorModal>
      )}
      {validateMintTierError && (
        <GenericErrorModal
          error={context.t("Something went wrong validating")}
          title={context.t("We could not validate your code!")}
          errorReset={validateMintTierErrorReset}
          primaryAction={{
            title: context.t("Close"),
            action: validateMintTierErrorReset,
          }}
          rawError={validateMintTierError?.attributes?.full_error_message}
        >
          <ErrorCodeResolver codes={validateMintTierError?.attributes?.errors}>
            <p>
              {context.t(
                "Unfortunately we could not validate your voucher code. Please try again. If the error remains please contact us at support@nft-samsung.de and include the full error message."
              )}
            </p>
          </ErrorCodeResolver>
        </GenericErrorModal>
      )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
MintingTierDetail.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default withRouter(MintingTierDetail);
